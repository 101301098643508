import styled from '@emotion/styled'
import { styles } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export interface Props {
  description?: string
  languageCode: string
  title?: string
}

export const InfoText = memo(function InfoText({
  title,
  description,
  languageCode,
}: Props) {
  return (
    <Container>
      <Wrapper>
        <Label>{useVocabularyData('useful-info', languageCode)}</Label>
        {title ? <Title>{title}</Title> : null}
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 9.375rem 8.938vw 0;

  @media (max-width: 1199px) {
    width: calc(100% - 2.1875rem);
    margin: 5rem 0 0 2.1875rem;
  }
`

const Wrapper = styled.div`
  position: relative;
  max-width: 68.125rem;
  margin: 0 auto;
  padding: 10.3125rem 6.875rem 9.375rem;
  background-color: ${({ theme }) => theme.colors.variants.neutralLight5};
  background-image: url('/wrapper_bg.png');
  background-repeat: no-repeat;
  background-position: center center;

  @media (max-width: 1199px) {
    max-width: 100%;
    padding: 8.3125rem 2.5rem 5.375rem;
  }

  @media (max-width: 550px) {
    background-size: 10.875rem 8.25rem;
    background-position: center 3.125rem;
  }
`

const Title = styled.div`
  ${styles.h3}
  text-transform: uppercase;
  margin-top: 3rem;

  @media (max-width: 1199px) {
    top: 6.125rem;
    left: -0.9375rem;
  }
`

const Label = styled.h3`
  ${styles.label}
  position: absolute;
  top: 8.125rem;
  left: -6.875rem;
  margin-bottom: 1.0625rem;

  @media (max-width: 1199px) {
    position: static;
  }
`

const Description = styled.div`
  ${styles.description}

  p {
    margin: 1.6rem 0;
  }

  @supports (initial-letter: 5) or (-webkit-initial-letter: 5) {
    &::first-letter {
      -webkit-initial-letter: unset;
      initial-letter: unset;
      padding: 0;
      margin-top: 0;
    }
  }
`
